import React from "react";

import {
  InputWrap,
  LogoContainer,
  Logo,
  SearchInput,
  BtnContainer,
  Column,
  Row,
  ItemWrap,
  ItemList,
  SkillTitle,
  ItemContainer,
  ItemSection,
  FilterSection,
  FilterList,
  FilterItem,
  FilterTitle,
  FilterCheckBox,
  FilterLogo,
  LogoSpan,
} from "../../pages/collection/StyleCollection";
import { useState } from "react";
import Box from "../../Component/box/Box";

import native from "../../assets/img/skill/native.png";
import ts from "../../assets/img/skill/Typescript.png";
import react from "../../assets/img/skill/reactSmall.png";
import js from "../../assets/img/skill/JavaScript.png";
import node from "../../assets/img/skill/Node.png";
import { useRecoilState } from "recoil";
import { filterState, searchTermState } from "../../atom/atom";
import Db from "../../data/db.json";
import { AddSvg, AddSvgContainer } from "../box/StyleBox";

const ItemTabs = () => {
  const [check, setCheck] = useState({
    ts: false,
    node: false,
    react: false,
    js: false,
    native: false,
    all: true,
  });

  const tsItems = Db.items.filter((item) => item.skill === "ts");
  const reactItems = Db.items.filter((item) => item.skill === "react");
  const jsItems = Db.items.filter((item) => item.skill === "js");
  const nodeItems = Db.items.filter((item) => item.skill === "node");
  const nativeItems = Db.items.filter((item) => item.skill === "native");

  const handleSkillClick = (checks: string) => {
    setCheck((prevCheck) => ({
      ...prevCheck,
      [checks]: true,
      ts: checks === "ts",
      react: checks === "react",
      js: checks === "js",
      all: checks === "all",
      node: checks === "node",
      native: checks === "native",
    }));
  };

  const [filter, setFilter] = useRecoilState(filterState);
  const HandleFilter = () => {
    setFilter(!filter);
  };
  const [filterSection, setFilterSection] = useState(false);
  const HandleFilterSection = () => {
    setFilterSection(!filterSection);
  };

  const [searchTerm, setSearchTerm] = useRecoilState(searchTermState);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredTsItems = tsItems.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredReactItems = reactItems.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredJsItems = jsItems.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredNodeItems = nodeItems.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredNativeItems = nativeItems.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const goAdd = () => {
    setSearchTerm("");
  };
  return (
    <>
      <InputWrap>
        <LogoContainer onClick={HandleFilterSection} isFilter={filterSection}>
          <Logo />
        </LogoContainer>

        <SearchInput
          type="text"
          placeholder="leemjaejun's projects , study examples"
          onChange={handleSearch}
        />

        <BtnContainer>
          <Column onClick={HandleFilter} isFilter={filter} />
          <Row onClick={HandleFilter} isFilter={filter} />
        </BtnContainer>
      </InputWrap>

      <ItemContainer>
        <FilterSection isFilter={filterSection}>
          <FilterList isFilter={filterSection}>
            <FilterItem>
              <FilterTitle>ALL</FilterTitle>
              <FilterCheckBox
                onClick={() => handleSkillClick("all")}
                isClick={check.all}
              ></FilterCheckBox>
            </FilterItem>
            <FilterItem>
              <FilterLogo logo={ts}></FilterLogo>
              <FilterTitle>TYPESCRIPT</FilterTitle>
              <FilterCheckBox
                onClick={() => handleSkillClick("ts")}
                isClick={check.ts}
              ></FilterCheckBox>
            </FilterItem>
            <FilterItem>
              <FilterLogo logo={native}></FilterLogo>
              <FilterTitle>NATIVE</FilterTitle>
              <FilterCheckBox
                onClick={() => handleSkillClick("native")}
                isClick={check.native}
              ></FilterCheckBox>
            </FilterItem>
            <FilterItem>
              <FilterLogo logo={node}></FilterLogo>
              <FilterTitle>NODE</FilterTitle>
              <FilterCheckBox
                onClick={() => handleSkillClick("node")}
                isClick={check.node}
              ></FilterCheckBox>
            </FilterItem>
            <FilterItem>
              <FilterLogo logo={react}></FilterLogo>
              <FilterTitle>REACT</FilterTitle>
              <FilterCheckBox
                onClick={() => handleSkillClick("react")}
                isClick={check.react}
              ></FilterCheckBox>
            </FilterItem>
            <FilterItem>
              <FilterLogo logo={js}></FilterLogo>
              <FilterTitle>JAVASCRIPT</FilterTitle>
              <FilterCheckBox
                onClick={() => handleSkillClick("js")}
                isClick={check.js}
              ></FilterCheckBox>
            </FilterItem>
          </FilterList>
        </FilterSection>

        <ItemSection>
          {filteredNativeItems.length > 0 && (check.all || check.native) && (
            <ItemList isFilter={filter}>
              <SkillTitle isFilter={filter}>
                <LogoSpan logo={native}></LogoSpan>React-Native
              </SkillTitle>
              <ItemWrap isFilter={filter}>
                {filteredNativeItems.map((item) => (
                  <Box
                    key={item.id}
                    itemImg={item.img}
                    skill={item.skill}
                    title={item.title}
                    transition={item.transition}
                    sub={item.sub}
                    linkto={""}
                    github={item.github}
                    go={item.id}
                  />
                ))}
                {filteredNativeItems.length < 0 && (
                  <AddSvgContainer onClick={goAdd}>
                    <AddSvg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_51_125"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_51_125)">
                        <path
                          d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                          fill="#6f36ff"
                        />
                      </g>
                    </AddSvg>
                  </AddSvgContainer>
                )}
              </ItemWrap>
            </ItemList>
          )}
          {filteredTsItems.length > 0 && (check.all || check.ts) && (
            <ItemList isFilter={filter}>
              <SkillTitle isFilter={filter}>
                <LogoSpan logo={ts}></LogoSpan>TYPESCRIPT
              </SkillTitle>
              <ItemWrap isFilter={filter}>
                {filteredTsItems.map((item) => (
                  <Box
                    key={item.id}
                    itemImg={item.img}
                    skill={item.skill}
                    title={item.title}
                    transition={item.transition}
                    sub={item.sub}
                    linkto={""}
                    github={item.github}
                    go={item.id}
                  />
                ))}
                {filteredTsItems.length < 5 && (
                  <AddSvgContainer onClick={goAdd}>
                    <AddSvg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_51_125"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_51_125)">
                        <path
                          d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                          fill="#6f36ff"
                        />
                      </g>
                    </AddSvg>
                  </AddSvgContainer>
                )}
              </ItemWrap>
            </ItemList>
          )}

          {filteredNodeItems.length > 0 && (check.all || check.node) && (
            <ItemList isFilter={filter}>
              <SkillTitle isFilter={filter}>
                <LogoSpan logo={node}></LogoSpan>Node
              </SkillTitle>
              <ItemWrap isFilter={filter}>
                {filteredNodeItems.map((item) => (
                  <Box
                    key={item.id}
                    itemImg={item.img}
                    skill={item.skill}
                    title={item.title}
                    transition={item.transition}
                    sub={item.sub}
                    linkto={""}
                    github={item.github}
                    go={item.id}
                  />
                ))}
                {filteredJsItems.length === 0 &&
                  filteredTsItems.length === 0 &&
                  filteredReactItems.length === 0 && (
                    <AddSvgContainer onClick={goAdd}>
                      <AddSvg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_51_125"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_51_125)">
                          <path
                            d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                            fill="#6f36ff"
                          />
                        </g>
                      </AddSvg>
                    </AddSvgContainer>
                  )}
              </ItemWrap>
            </ItemList>
          )}

          {filteredReactItems.length > 0 && (check.all || check.react) && (
            <ItemList isFilter={filter}>
              <SkillTitle isFilter={filter}>
                <LogoSpan logo={react}></LogoSpan>REACT
              </SkillTitle>
              <ItemWrap isFilter={filter}>
                {filteredReactItems.map((item) => (
                  <Box
                    key={item.id}
                    itemImg={item.img}
                    skill={item.skill}
                    title={item.title}
                    transition={item.transition}
                    sub={item.sub}
                    linkto={""}
                    github={item.github}
                    go={item.id}
                  />
                ))}
                {filteredReactItems.length < 4 && (
                  <AddSvgContainer onClick={goAdd}>
                    <AddSvg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_51_125"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_51_125)">
                        <path
                          d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                          fill="#6f36ff"
                        />
                      </g>
                    </AddSvg>
                  </AddSvgContainer>
                )}
              </ItemWrap>
            </ItemList>
          )}

          {filteredJsItems.length > 0 && (check.all || check.js) && (
            <ItemList isFilter={filter}>
              <SkillTitle isFilter={filter}>
                <LogoSpan logo={js}></LogoSpan>JAVASCRIPT
              </SkillTitle>
              <ItemWrap isFilter={filter}>
                {filteredJsItems.map((item) => (
                  <Box
                    key={item.id}
                    itemImg={item.img}
                    skill={item.skill}
                    title={item.title}
                    transition={item.transition}
                    sub={item.sub}
                    linkto={""}
                    github={item.github}
                    go={item.id}
                  />
                ))}
                {filteredJsItems.length < 3 && (
                  <AddSvgContainer onClick={goAdd}>
                    <AddSvg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_51_125"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_51_125)">
                        <path
                          d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                          fill="#6f36ff"
                        />
                      </g>
                    </AddSvg>
                  </AddSvgContainer>
                )}
              </ItemWrap>
            </ItemList>
          )}
        </ItemSection>
      </ItemContainer>
    </>
  );
};

export default ItemTabs;
